import React from "react";
import { Script } from "gatsby";
import { useSiteData } from "fixr/hooks/useSiteData";

export function Analytics() {
  const {
    settings: { analytics },
  } = useSiteData();

  return (
    <>
      {process.env.GATSBY_FIREBASE_MEASUREMENT_ID && (
        <GA4 id={process.env.GATSBY_FIREBASE_MEASUREMENT_ID} />
      )}
      {analytics.googleMeasurementId && (
        <GA4 id={analytics.googleMeasurementId} />
      )}
      {analytics.facebookPixelId && (
        <Script>{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${analytics.facebookPixelId}');
        fbq('track', 'PageView');
        `}</Script>
      )}
    </>
  );
}

function GA4({ id }: { id: string }) {
  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
      ></Script>
      <Script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${id}}'); 
        `}
      </Script>
    </>
  );
}
