import { graphql, useStaticQuery } from "gatsby";
import type { SiteSettings } from "./useSettings";

type SiteData = {
  siteId: string;
  name: string;
  baseUrl: string;
  settings: SiteSettings;
  urls: { id: string; url: string }[];
};

export function useSiteData() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteId
        baseUrl
        name
        urls {
          id
          url
        }
        settings {
          menus {
            header {
              id
              parentId
              link {
                linkType
                value
                label
                postType
                blank
              }
            }
            footer {
              id
              parentId
              link {
                linkType
                value
                label
                postType
                blank
              }
            }
          }
          socials {
            instagram
            twitter
            facebook
            tiktok
            youtube
          }
          analytics {
            googleMeasurementId
            facebookPixelId
          }
          seo {
            description
            openGraphImage {
              src
              width
              height
            }
          }
          general {
            siteName
            contactEmail
            contactPhone
            logo {
              src
              width
              height
            }
          }
        }
      }
    }
  `);

  return data.site as SiteData;
}
